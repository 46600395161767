<template>
  <loading-dialog :loading="$apollo.loading">
    <base-page-layout :title="franchiseName">
      <v-tabs v-model="tab" grow class="mb-5">
        <v-tab to="dados-da-rede">Dados da rede</v-tab>
        <v-tab to="landing-page">Configurar Landing Page</v-tab>
        <v-tab to="produtos-ativos">Produtos Ativos</v-tab>
        <v-tab to="acoes">Ações</v-tab>
      </v-tabs>
      <router-view :franchise="franchise" :headers-tab.sync="tab" />
    </base-page-layout>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_FRANCHISE } from '@/modules/franchises/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue')
  },
  data: () => ({
    tab: 0,
    franchiseId: ''
  }),
  computed: {
    franchiseName() {
      return this.franchise ? this.franchise.fantasy_name : ''
    }
  },
  apollo: {
    franchise: {
      query: QUERY_GET_FRANCHISE,
      fetchPolicy: 'network-only',
      variables() {
        return { franchise_id: this.franchiseId }
      },
      skip() {
        return !this.franchiseId
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    this.franchiseId = this.$route.params.id
  }
}
</script>
